import React, {Component} from "react"
import ReactDOM from 'react-dom'
import Layout from "components/Layout/Layout"
import Amplify, {API, Storage} from 'aws-amplify'
import awsconfig from '../../aws-exports.js'
import styled from "styled-components";
import {navigate} from 'gatsby'
import Cookies from "js-cookie"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input
} from 'reactstrap'
import {
  validateRequired,
  validateEmail,
  validatePhone,
  validateAmount,
  validateRequiredCheckbox,
  validateFile
} from 'js/validations'
import HeaderCopy from "components/shared/HeaderCopy"
import iconDropDown from "images/icons/icon-drop-down.svg"

const captchaSiteKey = "6LdfWPIpAAAAANkOaXSBrE4PLnEOaOfEmp0jM0f_"
// const captchaSiteKey = "6LdfWPIpAAAAANkOaXSBrE4PLnEOaOfEmp0jM0f_"
const oldCaptchaSiteKey = "6Le95OonAAAAALI1AkQxgkES39TPGdFtFGh3K5kU"
// const aladdinCaptchaSiteKey = "6Ld1Xa4UAAAAAAWXlgrYmeqKku8qw2apDa3jKpuZ"


Amplify.configure(awsconfig)

//
// const S3_BUCKET = 'lkeducations3bucket';
// const REGION = 'eu-west-2';
// const ACCESS_KEY = 'AKIA4BU7V3IFJEU3T36X';
// const SECRET_ACCESS_KEY = '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';
//
// const config = {
//     bucketName: S3_BUCKET,
//     region: REGION,
//     accessKeyId: ACCESS_KEY,
//     secretAccessKey: SECRET_ACCESS_KEY,
// }

const FormTheme = styled.div`
  label {
    font-size: 1.125rem;
    font-family: ${props => props.theme.font.family.light};
    width: 100%;
  }

  input:not([type="checkbox"]), select, textarea {
    background-color: ${props => props.theme.colors.superLightGrey};
    width: 100%;
    border-radius: 0;
    padding: 0 1rem;
    min-height: 3rem;
  }

  input[type="file"] {
    color: ${props => props.theme.colors.green};
  }

  select {
    background-image: url(${iconDropDown});
    background-position: calc(100% - 0.625rem) center;
    background-size: 15px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .invalid-feedback {
    color: ${props => props.theme.colors.red};
    font-family: ${props => props.theme.font.family.base};
    font-size: 0.875rem !important;
    padding: 12px;
  }

  .red {
    color: ${props => props.theme.colors.red};
  }

  a {
    color: ${props => props.theme.colors.red};
  }

  .form-check:not(.no-border) {
    border: 1px solid ${props => props.theme.colors.grey};
    padding: 1rem;
    margin-bottom: 1rem;

    label {
      margin-left: 3rem;
    }

    input {
      left: -1rem;
    }
  }

  .form-check-input {
    top: -4px;
    left: 0;
    width: 20px;
    height: 20px;
  }

  label.form-check-label.checkbox-btn {
    display: block;
    position: relative;
    padding-left: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`

const SubmitButton = styled.button`
  width: 198px;
  height: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.yellow};
  transition: all 150ms ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.black};
  }
`

class CircleOfLifeAwardForm extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      // nameOfSchool: 'School',
      // postcode: 'ASD',
      // teamName: 'Team',
      // teamSize: '8',
      // yearGroup: '12',
      // keyArea: 'Key area',
      // aim: 'This is the aim',
      // partner: 'Partner field',
      // yourProject: 'My project',
      // skillsDeveloped: 'None',
      // momentOfSuccess: 'There isn`t any',
      // overcameChallenge: 'I did so',
      // personCompleting: 'David',
      // role: 'Student',
      // email: 'asd@asd.com',
      // phoneNumber: '07415287831',
      //------------------------
      nameOfSchool: '',
      postcode: '',
      teamName: '',
      teamSize: '',
      yearGroup: '',
      keyArea: '',
      aim: '',
      partner: '',
      yourProject: '',
      skillsDeveloped: '',
      momentOfSuccess: '',
      overcameChallenge: '',
      personCompleting: '',
      role: '',
      email: '',
      phoneNumber: '',
      //--------------------------
      file1: {
        size: 0,
        type: "file"
      },
      file2: {
        size: 0,
        type: "file"
      },
      file3: {
        size: 0,
        type: "file"
      },
      file4: {
        size: 0,
        type: "file"
      },
      tandc: '',
      googleVerified: "",
      validate: {
        nameOfSchool: '',
        postcode: '',
        teamName: '',
        teamSize: '',
        yearGroup: '',
        keyArea: '',
        aim: '',
        partner: '',
        yourProject: '',
        skillsDeveloped: '',
        momentOfSuccess: '',
        overcameChallenge: '',
        file1: false,
        file2: false,
        file3: false,
        file4: false,
        personCompleting: '',
        role: '',
        email: '',
        emailErrMsg: 'A valid email is required',
        phoneNumber: '',
        phoneErrMsg: 'A valid UK phone number is required',
        tandc: '',
        googleVerified: '',
        googleVerifiedErrMsg: 'Google recaptcha is required'
      },
      submitBtnCopy: 'Submit',
      fileSizeExceeded: false,
      cookieAccepted: true
    }
    
    // Bind this to methods
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onGoogleVerify = this.onGoogleVerify.bind(this)
    
    // Bind this to validation methods
    this.validateRequired = validateRequired.bind(this);
    this.validateEmail = validateEmail.bind(this);
    this.validatePhone = validatePhone.bind(this);
    this.validateAmount = validateAmount.bind(this);
    this.validateRequiredCheckbox = validateRequiredCheckbox.bind(this);
    this.validateFile = validateFile.bind(this)
    
    // Create form ref
    this.form = React.createRef();
  }
  

  async onGoogleVerify(response) {
    console.log('GR')
    const { validate } = this.state
    const myInit = {
      headers: {},
      body: {
        recaptchaResponse: response,
      },
    }
    const res = await API.post(
      'LKEducationAPI',
      '/LKRecaptcha',
      myInit
    )
      .then(response => {
        // If there are errors update validation state
        console.log(response)
        if (response.event.success === false || response.event < 0.5) {
          validate.googleVerified = 'has-danger'
          console.log('FAILED')
          this.setState({
            validate,
            googleVerified: false,
          })
          throw Error('Failed recaptcha')
        } else {
          // success
          console.log('SUCCESS')
          validate.googleVerified = ''
          this.setState({
            validate,
            googleVerified: true,
          })
        }
      })
      .catch(error => {
        console.log('caught error', error)
        // handle errors and timeout error
        validate.googleVerified = 'has-danger'
        this.setState({
          validate,
          googleVerified: false,
        })
      })
  }

  componentDidMount() {
    // window.grecaptcha.enterprise.ready(async () => {
    //   const token = await window.grecaptcha.enterprise.execute('6LdfWPIpAAAAANkOaXSBrE4PLnEOaOfEmp0jM0f_', {action: 'LOGIN'});
    // });
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${captchaSiteKey}`
    script.addEventListener('load', () => {
      console.log('window.grecaptcha: loaded')
      const that = this
      if (typeof window.grecaptcha !== 'undefined') {
        window.grecaptcha.enterprise.ready(() => {
          window.grecaptcha.enterprise
            .execute(captchaSiteKey, { action: 'validate' })
            .then(token => {
              // console.log(token)
              that.onGoogleVerify(token)
            })
          // refresh token every minute to prevent expiration
          setInterval(() => {
            window.grecaptcha.enterprise
              .execute(captchaSiteKey, { action: 'validate' })
              .then(token => {
                that.onGoogleVerify(token)
              })
          }, 60000)
        })
      }
    })
    document.body.appendChild(script)
  }

  
  
  handleChangeNumbers = (evt, stateName, stateValue) => {
    const value = (evt.target.validity.valid) ? evt.target.value : stateValue;
    
    this.setState({[stateName]: value});
  }
  
  


  openDialog = e => {
    if (
      typeof window !== 'undefined' &&
      typeof window.__dataLayer !== 'undefined'
    ) {
      window.__dataLayer.OneTrustDialog(e)
    }
  }

  
  // Method to update field values in state on change
  async handleChange(e) {
    const target = e.target;
    let name = target.name
    let value = target.type === 'checkbox' ? target.checked : target.value;
    
    this.setState({
      [name]: value,
    });
  }
  onFileChange = async (event, fileName) => {
    let validate = this.state.validate;
    validate[fileName] = "";
  
    this.setState({
      fileSizeExceeded: false,
      validate
    });
  
    if (typeof event.target.files[0] === 'undefined') {
      this.setState({
        [fileName]: {
          type: "file",
          size: 0
        },
      });
    } else {
      const name = event.target.files[0].name;
      const modifiedFileName = name.replace(/[^A-Za-z0-9_\.-]/g, '-');
      const size = event.target.files[0].size;
      const value = event.target.files[0];
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/pdf"
      ];
      if (allowedTypes.includes(value.type)) {
        this.setState({
          [fileName]: {
            name: modifiedFileName,
            size,
            value,
            type: "file"
          },
        });
        this.validateFile(event, fileName);
      } else {
        validate[fileName] = "This file type is not allowed. Formats accepted: jpeg, png, gif, pdf. Total file size: 5MB";
        this.setState({
          validate
        });
      }
    }
  }
  
  // onFileChange = async (event, fileName) => {
  //   console.log(event)
  //   console.log(fileName)
  //   let validate = this.state.validate
    
  //   validate[fileName] = ""
    
  //   this.setState({
  //     fileSizeExceeded: false,
  //     validate
  //   })
    
  //   if (typeof event.target.files[0] === 'undefined') {
  //     validate[fileName] = "File is required"
  //     this.setState({
  //       [fileName]: {
  //         type: "file",
  //         size: 0
  //       },
  //       validate
  //     });
  //   } else {
  //     const name = event.target.files[0].name
  //     const modifiedFileName = name.replace(/[^A-Za-z0-9_\.-]/g, '-');
  //     const size = event.target.files[0].size
  //     const value = event.target.files[0]
  //     const allowedTypes = [
  //       "image/jpeg",
  //       "image/png",
  //       "image/gif",
  //       "application/pdf"
  //     ]
  //     if (allowedTypes.includes(value.type)) {
  //       this.setState({
  //         [fileName]: {
  //           name: modifiedFileName,
  //           size,
  //           value,
  //           type: "file"
  //         },
  //       })
  //     } else {
  //       validate[fileName] = "This file type is not allowed. Formats accepted: jpeg, png, gif, pdf. Total file size: 5MB"
  //       this.setState({
  //         validate
  //       })
  //     }
  //   }
  // }
  
  uploadFiles = async (fileName, modifiedFileName) => {
    let fileObject = this.state[fileName]
    const that = this
    let promise = new Promise(function (resolve, reject) {
      Storage.put(modifiedFileName, fileObject.value)
        .then(async item => {
          fileObject = {
            name: modifiedFileName,
            size: fileObject.size,
            value: fileObject.value,
            type: "file",
            url: `https://images.lionkingeducation.co.uk/?key=${modifiedFileName}`,
          }
          return resolve(fileObject)
        })
        .catch(err => reject(err))
    })
    
    return await promise
  }
  
  // Method to handle form submit
  async handleSubmit(e) {
    e.preventDefault();
    
    this.setState({
      submitBtnCopy: "Sending form"
    });
    
    const {validate} = this.state;
    
    const count = this.state.file1.size + this.state.file2.size + this.state.file3.size + this.state.file4.size;
    const fileSize = parseFloat(count / 1024 / 1024).toFixed(2);
    
    // Check file size
    if (fileSize > 5) {
      this.setState({
        fileSizeExceeded: true,
        submitBtnCopy: "Submit"
      });
      return;
    }
    
    // Create form data ready for api wrapper call
    let formData = {};
    for (let key in this.state) {
      if (typeof this.state[key].type !== "undefined" && this.state[key].type === "file") {
        if (typeof this.state[key].value !== "undefined") {
          formData[key] = await this.uploadFiles(key, this.state[key].name);
        }
      } else {
        formData[key] = this.state[key];
      }
    }
    const myInit = {
      body: {
        formData
      },
    };
    
    const res = await API.post('LKEducationAPI', '/LKCircleOfLife', myInit)
      .then((response) => {
        const event = response.event;
        console.log(event)
        // If there are errors update validation state
        if (event.errors !== false && event.errors !== undefined) {
          // Scroll top top of form
          const domNode = ReactDOM.findDOMNode(this.form.current);
          window.scrollTo({
            top: domNode.offsetTop,
            behavior: 'smooth'
          });
          
          const {validate} = this.state;
          
          for (let key in event.errors) {
            if (event.errors[key] === true) {
              validate[key] = 'has-danger';
            } else {
              validate[key] = event.errors[key];
            }
          }
          //re-enable the button
          this.setState({
            validate,
            fileSizeExceeded: false,
            submitBtnCopy: 'Submit'
          });
          
        } else {
          //re-enable the button
          this.setState({
            submitBtnCopy: 'Submit'
          });
          
          // Scroll top top of form
          const domNode = ReactDOM.findDOMNode(this.form.current);
          window.scrollTo({
            top: domNode.offsetTop,
            behavior: 'smooth'
          });
          if (typeof event.MessageId !== 'undefined') {
            navigate('/thank-you');
          } else {
            console.log(event.code);
          }
        }
      });
  }
  
  // async handleSubmit(e) {
  //   e.preventDefault();
    
  //   this.setState({
  //     submitBtnCopy: "Sending form"
  //   })
    
  //   const {validate} = this.state;
    
  //   const count = this.state.file1.size + this.state.file2.size + this.state.file3.size + this.state.file4.size
  //   const fileSize = parseFloat(count / 1024 / 1024).toFixed(2)
    
  //   // Check file size
  //   if (fileSize > 5) {
  //     this.setState({
  //       fileSizeExceeded: true,
  //       submitBtnCopy: "Submit"
  //     })
  //     return
  //   }
    
  //   // Create form data ready for api wrapper call
  //   let formData = {};
  //   for (let key in this.state) {
  //     if (typeof this.state[key].type !== "undefined" && this.state[key].type === "file") {
  //       if (typeof this.state[key].value === "undefined") {
  //         validate[key] = "File is required"
  //         this.setState({
  //           validate,
  //           submitBtnCopy: "Submit"
  //         });
  //         return
  //       }
  //       formData[key] = await this.uploadFiles(key, this.state[key].name)
  //     } else {
  //       formData[key] = this.state[key];
  //     }
  //   }
  //   const myInit = {
  //     body: {
  //       formData
  //     },
  //   };
    
  //   const res = await API.post('LKEducationAPI', '/LKCircleOfLife', myInit)
  //     .then((response) => {
  //       const event = response.event
        
  //       // If there are errors update validation state
  //       if (event.errors !== false && event.errors !== undefined) {
  //         // Scroll top top of form
  //         const domNode = ReactDOM.findDOMNode(this.form.current)
  //         window.scrollTo({
  //           top: domNode.offsetTop,
  //           behavior: 'smooth'
  //         })
          
  //         const {validate} = this.state
          
  //         for (let key in event.errors) {
  //           if (event.errors[key] === true) {
  //             validate[key] = 'has-danger'
  //           } else {
  //             validate[key] = event.errors[key]
  //           }
  //         }
  //         //re-enable the button
  //         this.setState({
  //           validate,
  //           fileSizeExceeded: false,
  //           submitBtnCopy: 'Submit'
  //         });
          
  //       } else {
  //         //re-enable the button
  //         this.setState({
  //           submitBtnCopy: 'Submit'
  //         });
          
  //         // Scroll top top of form
  //         const domNode = ReactDOM.findDOMNode(this.form.current)
  //         window.scrollTo({
  //           top: domNode.offsetTop,
  //           behavior: 'smooth'
  //         })
  //         if (typeof event.MessageId !== 'undefined') {
  //           navigate('/thank-you');
  //         } else {
  //           console.log(event.code)
  //         }
  //       }
  //     })
  // }
  
  openDialog = (e) => {
    if (typeof window !== 'undefined' && typeof window.__dataLayer !== 'undefined') {
      window.__dataLayer.OneTrustDialog(e)
    }
  }
  
  render() {
    const count = this.state.file1.size + this.state.file2.size + this.state.file3.size + this.state.file4.size
    
    return (
      <Layout slug="circle-of-life-award/application" title="The Circle of Life Award Application">
        <HeaderCopy title="The Circle of Life<br/>Award Application"/>
        <FormTheme>
          <Container fluid={true}>
            <Container>
              <>
                <h2 className="mb-3">Circle of Life Award Application Form</h2>
                <Row>
                  <Col xs={12}>
                    <p>This is your chance to tell us about your project and apply for the
                      Circle of
                      Life award!</p>
                    <p>This form should be completed by a teacher, project leader or another
                      nominated person. Please include evidence of your project including
                      photographs, testimonials from student participants and, if possible,
                      testimonials from project beneficiaries.</p>
                  </Col>
                </Row>
                {!this.state.cookieAccepted ?
                  <div className="text-center">
                    <p><strong>Note: To be able to see the form below and submit your details, you must accept the
                      cookies.</strong></p>
                    <a className="no-interstitial-check btn--red btn--block" onClick={(e) => this.openDialog(e)}
                       href="#" role="button">
                      Manage Your Cookie Settings
                    </a>
                  </div>
                  :
                  <React.Fragment>
                    <Form onSubmit={(e) => this.handleSubmit(e)} noValidate ref={this.form}>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="nameOfSchool">Name of school*</Label>
                            <Input
                              type="text"
                              name="nameOfSchool"
                              id="nameOfSchool"
                              value={this.state.nameOfSchool}
                              valid={this.state.validate.nameOfSchool === 'has-success'}
                              invalid={this.state.validate.nameOfSchool === 'has-danger'}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              Name of school is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="postcode">School postcode*</Label>
                            <Input
                              type="text"
                              name="postcode"
                              id="postcode"
                              value={this.state.postcode}
                              valid={this.state.validate.postcode === 'has-success'}
                              invalid={this.state.validate.postcode === 'has-danger'}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              School postcode is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="teamName">Name of team</Label>
                            <Input
                              type="text"
                              name="teamName"
                              id="teamName"
                              value={this.state.teamName}
                              valid={this.state.validate.teamName === 'has-success'}
                              invalid={this.state.validate.teamName === 'has-danger'}
                              onChange={e => {
                                this.handleChange(e)
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="teamSize">Number of students in team*</Label>
                            <Input
                              type="text"
                              name="teamSize"
                              id="teamSize"
                              value={this.state.teamSize}
                              valid={this.state.validate.teamSize === 'has-success'}
                              invalid={this.state.validate.teamSize === 'has-danger'}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              Number of students in team is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="yearGroup">Year groups involved*</Label>
                            <Input
                              type="text"
                              name="yearGroup"
                              id="yearGroup"
                              value={this.state.yearGroup}
                              valid={this.state.validate.yearGroup === 'has-success'}
                              invalid={this.state.validate.yearGroup === 'has-danger'}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              Year group is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="keyArea">Key area of interest*</Label>
                            <Input
                              type="text"
                              name="keyArea"
                              id="keyArea"
                              value={this.state.keyArea}
                              valid={this.state.validate.keyArea === 'has-success'}
                              invalid={this.state.validate.keyArea === 'has-danger'}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              Key area of interest is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="aim">Aim of project (in one sentence)*</Label>
                            <Input
                              type="text"
                              name="aim"
                              id="aim"
                              value={this.state.aim}
                              valid={this.state.validate.aim === 'has-success'}
                              invalid={this.state.validate.aim === 'has-danger'}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              Aim of project is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="partner">Partner organisations</Label>
                            <Input
                              type="text"
                              name="partner"
                              id="partner"
                              value={this.state.partner}
                              valid={this.state.validate.partner === 'has-success'}
                              invalid={this.state.validate.partner === 'has-danger'}
                              onChange={e => {
                                this.handleChange(e)
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col xs={12}>
                          <FormGroup>
                            <Label for="yourProject">Describe your project</Label>
                            <Input
                              type="textarea"
                              name="yourProject"
                              id="yourProject"
                              value={this.state.yourProject}
                              valid={this.state.validate.yourProject === 'has-success'}
                              invalid={this.state.validate.yourProject === 'has-danger'}
                              rows="5"
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              Describe your project is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col xs={12}>
                          <FormGroup>
                            <Label for="skillsDeveloped">Skills developed by the team (eg
                              leadership, organisation, collaboration, teamwork, flexibility,
                              research and consultation)</Label>
                            <Input
                              type="textarea"
                              name="skillsDeveloped"
                              id="skillsDeveloped"
                              value={this.state.skillsDeveloped}
                              valid={this.state.validate.skillsDeveloped === 'has-success'}
                              invalid={this.state.validate.skillsDeveloped === 'has-danger'}
                              rows="5"
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              Skills developed by the team is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col xs={12}>
                          <FormGroup>
                            <Label for="momentOfSuccess">Please describe a moment of
                              success</Label>
                            <Input
                              type="textarea"
                              name="momentOfSuccess"
                              id="momentOfSuccess"
                              value={this.state.momentOfSuccess}
                              valid={this.state.validate.momentOfSuccess === 'has-success'}
                              invalid={this.state.validate.momentOfSuccess === 'has-danger'}
                              rows="5"
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              Moment of success is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col xs={12}>
                          <FormGroup>
                            <Label for="overcameChallenge">Please describe when the team
                              overcame a
                              challenge</Label>
                            <Input
                              type="textarea"
                              name="overcameChallenge"
                              id="overcameChallenge"
                              value={this.state.overcameChallenge}
                              valid={this.state.validate.overcameChallenge === 'has-success'}
                              invalid={this.state.validate.overcameChallenge === 'has-danger'}
                              rows="5"
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              When the team overcame a challenge is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col xs={12}>
                          <FormGroup>
                            <Label>Upload up to 4 images of your project (Formats accepted:
                              jpeg,
                              png, gif, pdf. Total file size: 5MB)</Label>
                            <Label for="file1">File No. 1</Label>
                            <input onChange={e => {
                              this.onFileChange(e, 'file1')
                              this.validateFile(e, 'file1')
                            }}
                                   name="file1"
                                   id="file1"
                                   type="file"/>
                            {this.state.validate.file1 &&
                              <div className="invalid-feedback d-block">
                                {this.state.validate.file1}
                              </div>
                            }
                            <Label for="file2">File No. 2</Label>
                            <input onChange={e => {
                              this.onFileChange(e, 'file2')
                              this.validateFile(e, 'file2')
                            }}
                                   name="file2"
                                   id="file2"
                                   type="file"/>
                            {this.state.validate.file2 &&
                              <div className="invalid-feedback d-block">
                                {this.state.validate.file2}
                              </div>
                            }
                            
                            <Label for="file3">File No. 3</Label>
                            <input onChange={e => {
                              this.onFileChange(e, 'file3')
                              this.validateFile(e, 'file3')
                            }}
                                   name="file3"
                                   id="file3"
                                   type="file"/>
                            {this.state.validate.file3 &&
                              <div className="invalid-feedback d-block">
                                {this.state.validate.file3}
                              </div>
                            }
                            
                            <Label for="file4">File No. 4</Label>
                            <input onChange={e => {
                              this.onFileChange(e, 'file4')
                              this.validateFile(e, 'file4')
                            }}
                                   name="file4"
                                   id="file4"
                                   type="file"/>
                            {this.state.validate.file4 &&
                              <div className="invalid-feedback d-block">
                                {this.state.validate.file4}
                              </div>
                            }
                            
                            <div>Total selected filesize: <span
                              className={`${parseFloat(count / 1024 / 1024).toFixed(2) > 5 && 'red d-inline'}`}><strong>{parseFloat(count / 1024 / 1024).toFixed(2)}MB</strong></span>
                            </div>
                            
                            {this.state.fileSizeExceeded &&
                              <p className="invalid-feedback d-block">
                                Total file size cannot exceed 5MB
                              </p>
                            }
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="personCompleting">Name of person completing this
                              form*</Label>
                            <Input
                              type="text"
                              name="personCompleting"
                              id="personCompleting"
                              value={this.state.personCompleting}
                              valid={this.state.validate.personCompleting === 'has-success'}
                              invalid={this.state.validate.personCompleting === 'has-danger'}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              Name of person completing this form is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="role">Role (e.g. student of teacher)*</Label>
                            <Input
                              type="text"
                              name="role"
                              id="role"
                              value={this.state.role}
                              valid={this.state.validate.role === 'has-success'}
                              invalid={this.state.validate.role === 'has-danger'}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                              }}
                            />
                            <FormFeedback>
                              Role is required
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="email">Email:*</Label>
                            <Input
                              type="email"
                              name="email"
                              id="email"
                              value={this.state.email}
                              valid={this.state.validate.email === 'has-success'}
                              invalid={this.state.validate.email === 'has-danger'}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                                this.validateEmail(e)
                              }}
                            />
                            <FormFeedback>
                              {this.state.validate.emailErrMsg}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="phoneNumber">Phone number*</Label>
                            <Input
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              value={this.state.phoneNumber}
                              valid={this.state.validate.phoneNumber === 'has-success'}
                              invalid={this.state.validate.phoneNumber === 'has-danger'}
                              onChange={e => {
                                this.handleChange(e)
                                this.validateRequired(e)
                                this.validatePhone(e, 'phoneNumber', true)
                              }}
                            />
                            <FormFeedback>
                              {this.state.validate.phoneErrMsg}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row className="pb-4">
                        <Col md={12}>
                          <FormGroup className="no-border" check>
                            <Label for="tandc" check
                                   className="font-normal font-weight-light checkbox-btn">
                              <p className="mb-0">I have read and accept the <strong><a
                                href="https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/"
                                target="_blank" rel="noreferrer noopener">terms and
                                conditions</a></strong>*</p>
                              <Input
                                type="checkbox"
                                name="tandc"
                                id="tandc"
                                value={this.state.tandc}
                                valid={this.state.validate.tandc === 'has-success'}
                                invalid={this.state.validate.tandc === 'has-danger'}
                                onChange={e => {
                                  this.handleChange(e)
                                  this.validateRequiredCheckbox(e)
                                }}
                              />
                              <FormFeedback>
                                You have to accept the terms and conditions
                              </FormFeedback>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <p>This application will be sent to the Disney theatrical education team
                            who
                            will be in touch about arranging your award in the near future.</p>
                        </Col>
                      </Row>
                      <Row className="mb-4 mt-2">
                        <Col md={12}>
                          <SubmitButton
                            disabled={this.state.submitBtnCopy !== "Submit"}
                            className="btn--red btn--block">{this.state.submitBtnCopy}</SubmitButton>
                        </Col>
                      </Row>
                    </Form>
                    {/* <ReCaptcha
                      sitekey={captchaSiteKey}
                      action='CircleOfLifeAwardForm'
                      verifyCallback={this.onGoogleVerify}
                    /> */}
                  </React.Fragment>
                }
              </>
            </Container>
          </Container>
        </FormTheme>
      </Layout>
    )
  }
  
}

export default CircleOfLifeAwardForm
